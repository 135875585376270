@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

        .address {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif;
        }

        .address {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            background: #161623;
        }

        .address1 {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            flex-wrap: wrap;
            z-index: 1;
        }

        .address1 .card {
            margin-top: -160px;
            position: relative;
            width: 95vw;
            height: 200px;
            box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);
            cursor: default;
        }

        .address1 .card .content {
            padding: 20px;
            text-align: center;
            opacity: 1;
            transition: 0.5s;
        }


        .address .card .content h2 {
            position: absolute;
            top: -40px;
            right: 30px;
            font-size: 8em;
            color: rgba(0, 0, 0, 0.05);
            pointer-events: none;
        }

        .address .card .content h3 {
            font-size: 1.8em;
            color: #fff;
            z-index: 1;
        }

        .address1 .card .content p {
            font-size: 1em;
            color: #fff;
            font-weight: 300;
        }

        .address1 .card .content a {
            position: relative;
            display: inline-block;
            padding: 8px 20px;
            margin-top: 15px;
            background: #fff;
            color: #000;
            border-radius: 20px;
            text-decoration: none;
            font-weight: 500;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
        @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

        .clients {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif;
        }

        .clients {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            flex-wrap: wrap;
        }

        .box {
            position: relative;
            width: 300px;
            height: 400px;
            display: flex;
            justify-content: center;
            background: #060c21;
            margin: 40px;
        }

        .box::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: #fff;
            z-index: -1;
        }

        .box::after {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: #fff;
            z-index: -2;
            filter: blur(40px);
        }

        .box:nth-child(1)::before,
        .box:nth-child(1)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box:nth-child(2)::before,
        .box:nth-child(2)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box:nth-child(3)::before,
        .box:nth-child(3)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box:nth-child(4)::before,
        .box:nth-child(4)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box:nth-child(5)::before,
        .box:nth-child(5)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box:nth-child(6)::before,
        .box:nth-child(6)::after {
            background: linear-gradient(235deg, #ff005e, #010615, #5d3dee);
        }

        .box .glass {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            display: block;
            background: rgba(255, 255, 255, 0.1);
            pointer-events: none;
        }

        .box .content {
            padding: 20px;
            color: #fff !important;
        }
        html {
          scroll-behavior: smooth;
      }